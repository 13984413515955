
























import Vue, { PropType } from 'vue';
// @ts-ignore
import LazyHydrate from 'vue-lazy-hydration';
import ThePaginator from '@/components/lh-ui/ThePaginator/ThePaginator.vue';

export default Vue.extend({
  name: 'ThePaginatorWrapper',

  components: {
    ThePaginator,
    LazyHydrate,
  },

  props: {
    total: {
      type: Number as PropType<number>,
      required: true,
    },
    active: {
      type: Number as PropType<number>,
      required: true,
    },
    pagePrefix: {
      type: String as PropType<string>,
      default: 'page/',
    },
    routeBeginning: {
      type: String as PropType<string>,
      required: true,
    },
    isTrailingSlash: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
});
