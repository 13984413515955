














import Vue, { PropType } from 'vue';
import { getDateText } from '@devhacker/shared/utils/date';
import CategoriesList from '~/components/CategoriesList.vue';
import { isHealthPath } from '~/router';
import { getFullUrl } from '~/utils';

interface ILhCategoryItem {
  id: number | string;
  title: string;
  url: string;
  icon?: string;
}

const CATEGORIES_COUNT = 2;

export default Vue.extend({
  name: 'ArticleCardHeader',
  components: {
    CategoriesList,
  },
  props: {
    article: {
      type: Object as PropType<IArticle>,
      required: true,
    },
    hideCategories: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    categoriesCount: {
      type: Number as PropType<number>,
      default: CATEGORIES_COUNT,
    },
    excludedCategoryIds: {
      type: Array as PropType<number[]>,
      default: () => [],
    },
  },
  computed: {
    categories (): ILhCategoryItem[] {
      if (this.isHealth) {
        return this.article.meta.breadcrumb;
      }

      let categoreisToShow = this.article.categories;

      // Фильтруем категории исключения если есть любая другая
      if (categoreisToShow.length > 1) {
        categoreisToShow = categoreisToShow.filter(item => !this.excludedCategoryIds.includes(+item.id));
      }

      return categoreisToShow.slice(0, this.categoriesCount);
    },
    isHealth (): boolean {
      return isHealthPath(this.$route.path);
    },
    isDateVisible (): boolean {
      return Boolean(this.dateText) && !this.isPromo;
    },
    dateText (): string {
      return getDateText(this.article.date);
    },
    isPromo (): boolean {
      return this.article.isPromo;
    },
  },
  methods: {
    sendAnalytics (url: string, title: string): void {
      this.$sendYandexMetrika({
        level1: 'Клик_Хлебные крошки',
        level4: getFullUrl(url),
        level6: title,
      });
    },
  },
});
